<template>
	<div class="course">
		<div class="title">
			<h2><p>❤ 课程表 ❤ FOR QX</p>
			<el-select v-model="type" placeholder="选择科目" :popper-class="flag==2||flag==3?'course-items-dark course-items':'course-items'">
			    <el-option label="全部" value="-1"></el-option>
			    <el-option label="语文" value="0"></el-option>
				<el-option label="数学" value="1"></el-option>
				<el-option label="外语" value="2"></el-option>
				<el-option label="物理" value="3"></el-option>
				<el-option label="化学" value="4"></el-option>
				<el-option label="生物" value="5"></el-option>
				<el-option label="政治" value="6"></el-option>
				<el-option label="历史" value="7"></el-option>
				<el-option label="地理" value="8"></el-option>
				<el-option label="体育" value="9"></el-option>
			</el-select>
			<el-select v-model="flag" placeholder="选择模式" :popper-class="flag==2||flag==3?'course-items-dark course-items':'course-items'">
			    <el-option label="简约" value="0"></el-option>
			    <el-option label="彩色" value="1"></el-option>
				<el-option label="夜间简约" value="2"></el-option>
				<el-option label="夜间彩色" value="3"></el-option>
			</el-select></h2>
		</div>
		<el-table
		    :data="tableData"
			:span-method="objectSpanMethod"
		    border
		    style="width: 100%">
		    <el-table-column
		      prop="flag"
		      label=""
		      width="100">
		    </el-table-column>
			<el-table-column
			      label="时间"
			      width="150">
			      <template slot-scope="scope">
			        <i class="el-icon-time"></i>
			        <span style="margin-left: 5px" class="time">{{ scope.row.date }}</span>
			      </template>
			</el-table-column>
		    <el-table-column
		      prop="count"
		      label="节次"
		      width="100">
		    </el-table-column>
		    <el-table-column
			  prop="mon"
		      label="星期一">
		    </el-table-column>
		    <el-table-column
			  prop="tue"
		      label="星期二">
		    </el-table-column>
		    <el-table-column
			  prop="wed"
		      label="星期三">
		    </el-table-column>
			<el-table-column
			  prop="thu"
			  label="星期四">
			</el-table-column>
			<el-table-column
			  prop="fri"
			  label="星期五">
			</el-table-column>
			<el-table-column
			  prop="sat"
			  label="星期六">
			</el-table-column>
		  </el-table>
	</div>
</template>

<script>
export default {
	data() {
		return {
			type: null,
			flag: null,
			types: ['语文','数学','外语','物理','化学','生物','政治','历史','地理','体育','综合','---'],
			tableData: [{
				count: '早自习',
				flag: '早上',
				date: '06:30 ~ 07:30',
				mon: '外语',
				tue: '语文',
				wed: '综合',
				thu: '外语',
				fri: '语文',
				sat: '---',
			},
			{
				count: '第一节',
				flag: '早上',
				date: '08:05 ~ 08:50',
				mon: '外语',
				tue: '语文',
				wed: '生物',
				thu: '数学',
				fri: '生物',
				sat: '化学',
			},
			{	count: '第二节',
				flag: '早上',
				date: '09:00 ~ 09:45',
				mon: '数学',
				tue: '化学',
				wed: '数学',
				thu: '外语',
				fri: '语文',
				sat: '数学',
			},
			{	count: '第三节',
				flag: '早上',
				date: '10:15 ~ 11:00',
				mon: '语文',
				tue: '物理',
				wed: '语文',
				thu: '生物',
				fri: '数学',
				sat: '语文',
			},
			{	count: '第四节',
				flag: '早上',
				date: '11:10 ~ 11:55',
				mon: '化学',
				tue: '外语',
				wed: '外语',
				thu: '语文',
				fri: '物理',
				sat: '物理',
			},
			{	count: '第五节',
				flag: '下午',
				date: '14:20 ~ 15:05',
				mon: '生物',
				tue: '政治',
				wed: '政治',
				thu: '体育',
				fri: '外语',
				sat: '生物',
			},
			{	count: '第六节',
				flag: '下午',
				date: '15:15 ~ 16:00',
				mon: '历史',
				tue: '数学',
				wed: '体育',
				thu: '化学',
				fri: '历史',
				sat: '外语',
			},
			{	count: '第七节',
				flag: '下午',
				date: '16:10 ~ 16:55',
				mon: '物理',
				tue: '地理',
				wed: '化学',
				thu: '物理',
				fri: '地理',
				sat: '---',
			},
			{	count: '午自习',
				flag: '下午',
				date: '17:25 ~ 18:10',
				mon: '---',
				tue: '---',
				wed: '---',
				thu: '---',
				fri: '---',
				sat: '---',
			},
			{	count: '晚 (一)',
			    flag: '晚上',
				date: '19:10 ~ 21:10',
				mon: '化学',
				tue: '外语',
				wed: '语文',
				thu: '数学',
				fri: '生物',
				sat: '物理',
			},
			{	count: '晚 (二)',
			    flag: '晚上',
				date: '21:30 ~ 22:30',
				mon: '化学',
				tue: '外语',
				wed: '语文',
				thu: '数学',
				fri: '生物',
				sat: '物理',
			},
			],
			colors: ["#8282ef","#409eff","#f56c6c","#0005a2","#73ff2d","green","#305e8e","#e6a23c","#8940ff","#70757d","#bf62bf"],
		}
	},
	methods: {
		initData(){
			let cells = document.getElementsByClassName('cell')
			for (let cell of cells){
				if(this.types.indexOf(cell.innerText) != -1){
					if(cell.innerText=='语文'){
						cell.style.color='#8282ef'
					} else
					if(cell.innerText=='外语'){
						cell.style.color='#f56c6c'
					} else
					if(cell.innerText=='化学'){
						cell.style.color='#73ff2d'
					} else
					if(cell.innerText=='生物'){
						cell.style.color='green'
					} else
					if(cell.innerText=='物理'){
						cell.style.color='#0005a2'
					} else
					if(cell.innerText=='数学'){
						cell.style.color='#409eff'
					} else
					if(cell.innerText=='地理'){
						cell.style.color='#8940ff'
					} else
					if(cell.innerText=='政治'){
						cell.style.color='#305e8e'
					} else
					if(cell.innerText=='历史'){
						cell.style.color='#e6a23c'
					} else
					if(cell.innerText=='体育'){
						cell.style.color='#70757d'
					} else
					if(cell.innerText=='---'||cell.innerText=='综合'){
						cell.style.color='#bf62bf'
					}
				}
			}
		},
		simple(){
			let cells = document.getElementsByClassName('cell')
			for (let cell of cells){
				if(this.types.indexOf(cell.innerText) != -1){
					cell.style.color='#bf62bf'
				}
			}
		},
		 objectSpanMethod({ rowIndex, columnIndex }) {
		        if (columnIndex === 0) {
		          if (rowIndex == 0 ) {
		            return {
		              rowspan: 5,
		              colspan: 1
		            };
		          } else if(rowIndex == 5 ){
					  return {
					    rowspan: 4,
					    colspan: 1
					  };
				  }else if(rowIndex == 9){
					  return {
					    rowspan: 2,
					    colspan: 1
					  };
				  } else{
		            return {
		              rowspan: 0,
		              colspan: 1
		            };
		        }
		    }
		},
		change(){
			let course = document.getElementsByClassName('course')[0]
			document.getElementsByClassName('el-select-dropdown')
			if(this.flag == 2 || this.flag == 3){
				course.classList.add('qc-dark')
				this.flag == 2 ? this.simple():this.initData()
			}else if(this.flag == 0 || this.flag == 1){
				course.classList.remove('qc-dark')
				this.flag == 0 ? this.simple():this.initData()
			}else{
				this.simple()
			}
		},
		setCookie(num){
			this.$cookies.set("theme-qc-course", num , "1m")
		}
	},
	watch: {
		type(){
			let cells = document.getElementsByClassName('cell')
			for (let cell of cells){
				if(this.type == -1 ){
					this.change()
				}else if(this.types.indexOf(cell.innerText) != -1){
					if (cell.innerText != this.types[this.type]) {
						cell.style.color = "rgba(255,255,255,.01)"
					} else{
						if(this.flag == 1 || this.flag == 3){
							cell.style.color = this.colors[this.type]
						}else{
							cell.style.color = this.colors[10]
						}
					}
				}
			}
		},
		flag(){
			this.setCookie(this.flag)
			this.$emit('themeChange',this.flag)
			this.change()
		}
	},
	mounted() {
		setTimeout(() => {
			if(this.$cookies.isKey("theme-qc-course")){
				this.flag = parseInt(this.$cookies.get("theme-qc-course"))
			}else{
				this.simple()
			}
		},1000)
	}
}
</script>

<style>
.course{
	position: relative;
	width: 70%;
	margin: 0 auto;
	top: 10%;
}
.course .title{
	background-color: #d058d0;
	padding: 1em;
	border-radius: 1em 1em 0 0;
}
.course .title h2{
	position: relative;
	color: white;
}
.course .title h2>p{
	display: inline-block;
}
.course .title .el-select{
	width: 95px;
}
.course .title .el-select:nth-child(3){
	position: absolute;
	right: 110px;
}
.course .title .el-select:nth-child(2){
	position: absolute;
	right: 0;
}
.course .title .el-input__inner{
	color: whitesmoke;
	background-color: #d058d0;
	border: 1px solid #b566b5;
	padding: 0 20px 0 7px;
}
.course .title .el-select .el-input.is-focus .el-input__inner{
	border-color: #e664e6;
}
.course-items .el-select-dropdown__item{
	color: #bf62bf;
}
.course-items .el-select-dropdown__list .selected{
	color: #409EFF !important;
}
.course .el-table--border, .el-table--group{
	border-radius: 0 0 1em 1em;
	border: 3px solid #d058d0;
}
.course .el-table .cell{
	font-size: 1.1em;
	color: #bf62bf;
}
.course .el-table td, .el-table th.is-leaf{
	text-align: center;
	border-right: 1px solid #c483d4;
	border-bottom: 1px solid #c483d4;
}
.course .el-table__row:nth-child(even){
	background-color: #efe6e6;
}
.el-table_1_column_1{
	background-color: #FFF;
}
.course .time{
	font-size: .9em;
}
.el-table--enable-row-transition .el-table__body td{
	transition: none;
}
.qc-dark .title{
	background-color: rgba(0,0,0,.3);
}
.qc-dark .title .el-input__inner{
	background-color: rgba(0,0,0,.3);
	border-radius: 10px;
	border: 1px solid #3a383a;
}
.qc-dark .title .el-select .el-input.is-focus .el-input__inner{
	border-color: #6d606d;
}
.qc-dark .el-select-dropdown{
	border: 1px solid #5d5f65;
	background-color: #000;
}
.qc-dark .el-select-dropdown__list .course-item{
	color: #dcc4c4;
}
.qc-dark .el-table--border, .el-table--group{
	border: 3px solid rgba(0,0,0,.5);
}
.qc-dark .el-table__row,.qc-dark .el-table th{
	background-color: rgba(0,0,0,.2);
}
.qc-dark .el-table__row:nth-child(even){
	background-color: rgba(0,0,0,.3);
}
.qc-dark .el-table_1_column_1{
	background-color: rgba(0,0,0,.2);
}
.qc-dark .el-table--enable-row-hover .el-table__body tr:hover>td{
	background-color: #35203a !important;
}
.qc-dark .el-table, .el-table__expanded-cell,.qc-dark .el-table th,.qc-dark .el-table tr{
	background-color: rgba(0,0,0,.2);
}
.course-items-dark{
	border: 1px solid #7d7e7f;
	background-color: rgba(0,0,0,.5);
}
.course-items-dark .el-select-dropdown__item{
	color: #fff;
}
.course-items-dark .el-select-dropdown__list .selected{
	color: #409EFF !important;
}
.course-items-dark .el-select-dropdown__item.hover,.course-items-dark .el-select-dropdown__item:hover{
	background-color: #43464a;
}
@media only screen and (max-width: 770px) {
	.course{
		width: 100%;
		top: 2%;
	}
	.course .title h2>p{
		display: block;
	}
	.course .el-table{
		overflow: unset;
		max-width: unset;
	}
	.course .title .el-select:nth-child(2){
		position: relative;
		right: unset;
	}
	.course .title .el-select:nth-child(3){
		position: relative;
		right: unset;
	}
	.course .el-table .cell{
		font-size: 1em;
	}
	.el-table td, .el-table th{
		padding: 8px 0;
	}
}
</style>
