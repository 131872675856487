<template>
	<div :class="themeFlag==2||themeFlag==3?'qc-index qc-index-dark':'qc-index'">
		<Stars></Stars>
		<div class="qc-course">
			<Course @themeChange='themeChange'></Course>
		</div>
	</div>
</template>

<script>
import Course from '@/components/Course.vue'
import Stars from '@/components/Stars.vue'
export default{
	components: {
		Course,
		Stars
	},
	data() {
		return {
			themeFlag: '',
		}
	},
	methods: {
		themeChange(flag){
			this.themeFlag = flag
		}
	},
	mounted() {
		this.$store.commit('showLoading')
		setTimeout(() => {
			if(this.$cookies.isKey("theme-qc-course")){
				this.themeFlag = parseInt(this.$cookies.get("theme-qc-course"))
			}
		},100)
	}
}
</script>

<style>
.qc-course{
	position: relative;
	width: 100%;
	height: 100%;
}
#bottom-bar{
	display: none;
	opacity: 0;
	z-index: -9;
	margin: 0 !important;
}
</style>
